<div class="container">
  <ng-container *ngFor="let yearConcert of yearsConcert">
    <div class="frise-element year row justify-content-center align-items-end">
      <div class="year-border">
        <span class="year-text">{{ yearConcert.year }}</span>
        <div class="year-back"></div>
      </div>
      <ng-container *ngFor="let concert of yearConcert.concerts">
        <div class="concert col">
          <a class="concert-link" href="{{ concert.playlistUrl }}" target="_blank">
            <span class="concert-text">Voir les vidéos du concert</span>
            <img class="cover" src="{{ concert.imgUrl }}">
            <span *ngIf="concert.day !== 0"><ng-container *ngIf="concert.day < 10">0</ng-container>{{ concert.day }}</span>
            <span>{{ concert.month }}</span>
          </a>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="calliloup"></div>

  <div class="guitare guitare-1"></div>
  <div class="drums drums-1"></div>
  <div class="sax sax-1"></div>
  <div class="trumpet trumpet-1"></div>

  <div class="guitare guitare-2"></div>
  <div class="drums drums-2"></div>
  <div class="sax sax-2"></div>
  <div class="trumpet trumpet-2"></div>

  <div class="guitare guitare-3"></div>
  <div class="drums drums-3"></div>
  <div class="sax sax-3"></div>
  <div class="trumpet trumpet-3"></div>

  <div class="guitare guitare-4"></div>
</div>


