<div class="frise">
  <div class="frise-element header">
    <h1>Ateliers Musiques Actuelles de Persan</h1>
  </div>
  <router-outlet></router-outlet>
  <app-loader></app-loader>
  <div class="frise-element footer">
    Site géré et hébergé par Erwan Moreau - <a href="https://github.com/pixeltraits" target="_blank">Pixeltraits</a>
  </div>
</div>

