import { NgModule } from '@angular/core';

import { LoaderService } from './components/non-blocking-loader/loader.service';
import { LoaderComponent } from './components/non-blocking-loader/loader.component';


@NgModule({
  imports: [],
  declarations: [
    LoaderComponent
  ],
  providers: [
    LoaderService
  ],
  exports: [
    LoaderComponent
  ]
})
export class SharedModule {
}
