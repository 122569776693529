import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { YearConcert } from './app.interfaces';

@Injectable()
export class AppService {

  constructor() {
  }

  getYearsConcert(): Observable<YearConcert[]> {
    return of([
      {
        year: 2021,
        concerts: [
          {
            imgUrl: '/assets/images/concerts/maquetteType3.jpg',
            day: 26,
            month: 'Juin',
            year: 2021,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/1cad4712-0782-4e73-84a4-9b4fe7e789c2'
          }
        ]
      },
      {
        year: 2020,
        concerts: [
          {
            imgUrl: '/assets/images/concerts/maquette01022020.jpg',
            day: 1,
            month: 'Février',
            year: 2020,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/e8060916-6ad0-48ae-835e-1c465aec46d7'
          },
          {
            imgUrl: '/assets/images/concerts/maquette17102020.jpg',
            day: 17,
            month: 'Octobre',
            year: 2020,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/ec58a792-9c4a-4197-a019-3b3285a415d0'
          }
        ]
      },
      {
        year: 2019,
        concerts: [
          {
            imgUrl: '/assets/images/concerts/maquette01022019.jpg',
            day: 2,
            month: 'Février',
            year: 2019,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/80b63aca-409d-4e28-83e3-9adc0b1a3119'
          },
          {
            imgUrl: '/assets/images/concerts/maquette21062019.jpg',
            day: 21,
            month: 'Juin',
            year: 2019,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/efffd966-4dcf-4d06-9aa8-ece2dd51d294'
          },
          {
            imgUrl: '/assets/images/concerts/maquetteType3.jpg',
            day: 23,
            month: 'Juin',
            year: 2019,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/f60aaa92-4ddc-4e61-aff1-e1ab98dc8d1d'
          },
          {
            imgUrl: '/assets/images/concerts/maquette29062019.jpg',
            day: 29,
            month: 'Juin',
            year: 2019,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/c6958e65-2158-4bc4-bed0-3ededf20126c'
          }
        ]
      },
      {
        year: 2018,
        concerts: [
          {
            imgUrl: '/assets/images/concerts/maquette27012018.jpg',
            day: 27,
            month: 'Janvier',
            year: 2018,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/6ab4ab1e-01eb-45ad-b2f7-d78210e306f0'
          },
          {
            imgUrl: '/assets/images/concerts/maquette30062018.jpg',
            day: 30,
            month: 'Juin',
            year: 2018,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/f0e99f0a-285c-48c0-adf8-83027e6994e8'
          }
        ]
      },
      {
        year: 2017,
        concerts: [
          {
            imgUrl: '/assets/images/concerts/maquette01072017.jpg',
            day: 1,
            month: 'Juillet',
            year: 2017,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/bf13b944-66c0-4a98-882a-d4b88471bafd'
          }
        ]
      },
      {
        year: 2016,
        concerts: [
          {
            imgUrl: '/assets/images/concerts/maquette02072016.jpg',
            day: 2,
            month: 'Juillet',
            year: 2016,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/bc35291b-7fd9-43e8-912f-2509d7c394c1'
          }
        ]
      },
      {
        year: 2015,
        concerts: [
          {
            imgUrl: '/assets/images/concerts/maquette04072015.jpg',
            day: 4,
            month: 'Juillet',
            year: 2015,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/e0d7cf8b-005c-4d0f-af87-08d8a3996e37'
          }
        ]
      },
      {
        year: 2014,
        concerts: [
          {
            imgUrl: '/assets/images/concerts/maquette01022014.jpg',
            day: 1,
            month: 'Février',
            year: 2014,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/9a40c220-3394-4a07-b816-7d5e05eff983'
          },
          {
            imgUrl: '/assets/images/concerts/maquetteType1.jpg',
            day: 0,
            month: 'Mai',
            year: 2014,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/cfdecca7-dc55-4820-a6b7-c64b08890856'
          },
          {
            imgUrl: '/assets/images/concerts/maquette05072014.jpg',
            day: 5,
            month: 'Juillet',
            year: 2014,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/4ebd4372-21b3-4680-9d7a-f68aa192b872'
          }
        ]
      },
      {
        year: 2013,
        concerts: [
          {
            imgUrl: '/assets/images/concerts/maquette06072013.jpg',
            day: 6,
            month: 'Juillet',
            year: 2013,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/2c97b2c7-5115-473f-b819-9857ded16713'
          }
        ]
      },
      {
        year: 2012,
        concerts: [
          {
            imgUrl: '/assets/images/concerts/maquetteType2.jpg',
            day: 28,
            month: 'Janvier',
            year: 2012,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/12900d2b-22cc-4be1-8a33-e401fdc57112'
          },
          {
            imgUrl: '/assets/images/concerts/maquetteType1.jpg',
            day: 7,
            month: 'Avril',
            year: 2012,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/3b1a9f7d-b0a6-49be-9c7f-d7a73c309bd1'
          },
          {
            imgUrl: '/assets/images/concerts/maquette03072012.png',
            day: 3,
            month: 'Juillet',
            year: 2012,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/8a8f7407-8b22-4b43-b194-397058a7b7d7'
          }
        ]
      },
      {
        year: 2011,
        concerts: [
          {
            imgUrl: '/assets/images/concerts/maquetteType2.jpg',
            day: 29,
            month: 'Janvier',
            year: 2011,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/a6648f73-7f4e-4241-a73f-30541aaace07'
          },
          {
            imgUrl: '/assets/images/concerts/maquetteType3.jpg',
            day: 21,
            month: 'Juin',
            year: 2011,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/02096948-a895-4315-b6e4-081bcfa78a80'
          }
        ]
      },
      {
        year: 2010,
        concerts: [
          {
            imgUrl: '/assets/images/concerts/maquetteType3.jpg',
            day: 25,
            month: 'Juin',
            year: 2010,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/9dd6dea7-538c-480c-b7f0-200626f0ee03'
          }
        ]
      },
      {
        year: 2009,
        concerts: [
          {
            imgUrl: '/assets/images/concerts/maquetteType3.jpg',
            day: 0,
            month: 'Mars',
            year: 2009,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/9a086e90-7537-4ac3-bc6c-765a4b30e800'
          },
          {
            imgUrl: '/assets/images/concerts/maquetteType3.jpg',
            day: 3,
            month: 'Juillet',
            year: 2009,
            playlistUrl: 'https://peertube.pixeltraits.com/videos/watch/playlist/33a6676b-7eca-4efc-82a6-c74ad45aadac'
          }
        ]
      }
    ]);
  }

}
