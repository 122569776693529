import { Component } from '@angular/core';

import { LoaderService } from './loader.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  loaderState = false;

  constructor(
    private loaderService: LoaderService,
    private router: Router
  ) {
    this.loaderService.getLoaderState().subscribe((state: boolean) => {
      this.loaderState = state;
    });

    this.router.events.subscribe(event => {
      const navStartState = event instanceof NavigationStart;
      const navEndState = event instanceof NavigationEnd;
      const navEvent = event instanceof NavigationStart || event instanceof NavigationEnd ? event : null;

      if (navEvent) {
        if (navStartState) {
          this.loaderService.putLoaderOn();
        }
        if (navEndState) {
          this.loaderService.putLoaderOff();
        }
      }
    });
  }

}
