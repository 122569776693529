import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { YearConcert } from '../app.interfaces';


@Component({
  selector: 'app-concerts',
  templateUrl: './concerts.component.html',
  styleUrls: ['./concerts.component.scss']
})
export class ConcertsComponent {

  yearsConcert: YearConcert[];

  constructor(
    private route: ActivatedRoute
  ) {
    this.yearsConcert = this.route.snapshot.data.yearsConcert;
  }

}
