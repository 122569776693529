import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({providedIn: 'root'})
export class LoaderService {

  private loaderBS = new BehaviorSubject(false);

  getLoaderState(): Observable<boolean> {
    return this.loaderBS;
  }

  putLoaderOn(): void {
    this.loaderBS.next(true);
  }

  putLoaderOff(): void {
    this.loaderBS.next(false);
  }

}
