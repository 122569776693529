import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { YearConcert } from './app.interfaces';
import { AppService } from './app.service';


@Injectable()
export class AppResolver implements Resolve<YearConcert[]> {

  constructor(private appService: AppService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<YearConcert[]> {
    return this.appService.getYearsConcert();
  }

}
