import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppResolver } from './app.resolver';
import { ConcertsComponent } from './concerts/concerts.component';


const routes: Routes = [
  {
    path: '',
    component: ConcertsComponent,
    resolve: {
      yearsConcert: AppResolver
    }
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
